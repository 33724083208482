import { CLink } from '@/CLink';
import { PlainBtn, PrimaryBtn, SecondaryBtn } from '@/buttons';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav } from './DesktopNavPrimitive';
import { MobNav } from './MobNav';
import { navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    padding-top: 13px;
    padding-bottom: 13px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.gray1};
    border-bottom: 1px solid transparent;
    transition: border-color 0.3s ease-in-out;

    > div {
        ${flexSpace};
        max-width: 1840px;
        margin: 0 auto;
    }

    @media (min-width: 1280px) {
        padding-top: 24px;
        padding-bottom: 16px;
        padding-left: 32px;
        padding-right: 32px;
    }

    ${({ scrolled }) =>
        scrolled &&
        css`
            border-color: ${theme.colors.gray2};
        `};
`;

const HomeLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 1329px) {
        > .gatsby-image-wrapper {
            max-width: 174px;
            height: 40.2px;
        }
    }
`;

const InfoBox = styled.div`
    @media (min-width: 1280px) {
        width: 100%;
        margin-left: 32px;
    }
`;

const Btns = styled.div`
    ${flexCenter};
    justify-content: flex-end;
    gap: 16px;

    > a:nth-of-type(2) {
        > svg {
            width: 19px;
            height: 19px;
        }
    }

    @media (max-width: 767px) {
        > a {
            display: none;

            :last-of-type {
                display: flex;
                margin-right: 16px;

                > svg {
                    path {
                        fill: ${({ theme }) => theme.colors.blue4};
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        > a:last-of-type {
            display: none;
        }
    }

    @media (min-width: 1280px) {
        margin-bottom: 24px;
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    width: 100%;
    display: none;

    @media (min-width: 1280px) {
        display: block;
    }
`;

const menuStyles = css`
    justify-content: space-between;
    max-width: 1280px;
    margin-left: auto;

    li {
        line-height: 150%;

        @media (max-width: 1899px) {
            :last-of-type:not(:first-of-type) > div:last-of-type {
                right: 0;
                left: unset;
            }
        }
    }
`;

const subMenuStyles = css`
    display: block;

    li {
        line-height: 150%;
        position: static !important;
    }
`;

const contentStyles = css`
    position: absolute;
    min-width: 328px;
    width: max-content;
    z-index: 100;
    border: 1px solid ${theme.colors.gray2};
    background-color: ${theme.colors.gray1};
    border-top: none;
    top: calc((1.125rem * 1.6) + 17px);

    > ul {
        max-height: calc(100vh - 240px);
        overflow-y: auto;
    }
`;

const subContentStyles = css`
    position: absolute;
    top: unset !important;
    margin-top: calc(-1 * ((1.125rem * 1.6) + 25px));
    left: 100%;
    min-width: max-content;
    width: 100%;
    z-index: 101;
    border: 1px solid ${theme.colors.gray2};
    background-color: ${theme.colors.gray1};
    border-left: none;

    > ul {
        max-height: calc(100vh - 440px);
        overflow-y: auto;
    }
`;

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const HomeLogo = () => (
    <HomeLink to="/">
        <StaticImage
            src="../../../assets/images/layout/logo.png"
            alt="dallas periodontics and dental implants"
            width={230}
            height={53}
            loading="eager"
        />
    </HomeLink>
);

// link can be /meet-the-doctors/#dr-herbert or #dr-herbert
export const scrollToSection = (link: string) => {
    const fullLink = typeof link === 'string' ? link.split('#') : '';
    const element = document.querySelector(`#${fullLink[1] || ''}`);
    if (!element) {
        navigate(fullLink[0], {
            state: {
                tag: `#${fullLink[1] || ''}`,
            },
        });
    }
    const y = element && element.getBoundingClientRect().top + window.scrollY - 76;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

export const Nav = () => {
    const [dialogsClosed, setDialogsClosed] = useState(false);
    const closeAllDialogs = () => setDialogsClosed(open => !open);

    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <div>
                    <HomeLogo />
                    <InfoBox>
                        <Btns>
                            <SecondaryBtn
                                as={CLink}
                                to="https://patientportal.carestack.com/?dn=lrabe/#/online-appointments"
                                css={theme => css`
                                    box-shadow: inset 0 0 0 2px ${theme.colors.blue4};
                                `}
                            >
                                schedule an appointment
                            </SecondaryBtn>
                            <PrimaryBtn as={CLink} to={siteInfo.phone.link}>
                                <PhoneIcon /> {siteInfo.phone.text}
                            </PrimaryBtn>
                            <PlainBtn
                                as={CLink}
                                to={siteInfo.phone.link}
                                aria-label={`call us at ${siteInfo.phone.text}`}
                            >
                                <PhoneIcon />
                            </PlainBtn>
                            <MobNav
                                dialogsClosed={dialogsClosed}
                                closeAllDialogs={closeAllDialogs}
                            />
                        </Btns>
                        <DesktopNavMenu
                            menuStyles={menuStyles}
                            contentStyles={contentStyles}
                            subContentStyles={subContentStyles}
                            subMenuStyles={subMenuStyles}
                            links={navLinks}
                        />
                    </InfoBox>
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
